import React, { useRef } from "react";
import { useFrame } from "react-three-fiber";

function Cube() {
  const ref = useRef();

  useFrame(() => (ref.current.rotation.x = ref.current.rotation.y += 0.01));

  return (
    <>
      <mesh ref={ref}>
        <boxGeometry attach="geometry" args={[1.5, 1.5, 1.5]} />
        <meshNormalMaterial attach="material"  />
      </mesh>
    </>
  );
}

export default Cube;
