import React from "react";
import { Line } from "rc-progress";
import Cube from "./reactThreeFiber/cube";
import { Canvas } from "react-three-fiber";
import { motion } from "framer-motion";

function App() {
  const SkillCard = ({ url, name, rate, percent, imageWidth = "w-20" }) => {
    return (
      <div>
        <section className="w-full min-h-48 bg-white rounded-md shadow-lg p-3">
          <section className="h-31">
            <img
              src={url}
              className={`${imageWidth} m-auto my-3`}
              alt="not-found"
            />
          </section>
          <p className="font-medium text-center mb-1">{name}</p>
          <p className="font-medium text-center text-sm mb-1">{rate} / 5</p>
          <Line percent={percent} strokeWidth={4} strokeColor="#eab308" />
        </section>
      </div>
    );
  };

  const SkillCard2 = ({ name, percent }) => {
    return (
      <div>
        <section className="flex justify-between">
          <h5 className="font-medium">{name}</h5>
          <h5 className="font-medium">{percent} %</h5>
        </section>
        <Line percent={percent} strokeWidth={1.5} strokeColor="#eab308" />
      </div>
    );
  };

  return (
    <section>
      {/* header */}
      <section className="fixed top-0 w-full py-4 px-10 bg-neutral-900 text-slate-300 z-50">
        <section className="flex">
          <div className="flex-1">
            <div className="w-10 h-10 bg-[url('http://surl.li/qvnqx')] bg-contain rounded-full"></div>
          </div>
          <div className="flex-1 font-medium text-md">
            <ul className="flex justify-between">
              <li className="cursor-pointer -translate-y-0 hover:-translate-y-1  ease-linear hover:duration-150  px-2 py-2">
                Home
              </li>
              <li className="cursor-pointer -translate-y-0 hover:-translate-y-1  ease-linear hover:duration-150  px-2 py-2">
                About
              </li>
              <li className="cursor-pointer -translate-y-0 hover:-translate-y-1  ease-linear hover:duration-150  px-2 py-2">
                Skills
              </li>
              <li className="cursor-pointer -translate-y-0 hover:-translate-y-1  ease-linear hover:duration-150  px-2 py-2">
                Portfolio
              </li>
              <a href="https://blog.mohammedasiq.com/" target="blank">
                <li className="cursor-pointer -translate-y-0 hover:-translate-y-1  ease-linear hover:duration-150  px-2 py-2">
                  Blogs
                </li>
              </a>
              <li className="cursor-pointer -translate-y-0 hover:-translate-y-1  ease-linear hover:duration-150  px-2 py-2">
                Contact
              </li>
            </ul>
          </div>
        </section>
      </section>
      {/* main page */}
      <section className="w-full h-screen bg-neutral-900 text-slate-300">
        <section className="flex items-center h-screen p-10">
          <motion.section
            className="flex-1 mt-20"
            initial={{ position: "relative", left: -300, opacity: 0 }}
            animate={{ opacity: 1, position: "relative", left: 0 }}
            transition={{ duration: 1, delay: 0.1 }}
          >
            <h4 className="text-xl text-yellow-500">Hi, I'm</h4>
            <h1 className="text-5xl font-black mt-1">Mohammed Asiq </h1>
            <h1 className="text-xl mt-5 mb-5 text-yellow-500 font-normal">
              MERN Stack Developer
            </h1>
            <p className="mb-7">
              I am a MERN stack developer. I'm aspiring to build web application
              from scratch by using react js. If you're looking for a web
              developer to build websites for your buisness or startups, feel
              free to contact me.
            </p>
            <button className="bg-yellow-500 text-neutral-900 font-medium px-4 py-2 mr-5 rounded-md w-40 hover:scale-105 ease-linear duration-150">
              Download CV
            </button>
            <button className="font-medium border-solid border w-40 px-4 py-2 rounded-md hover:scale-105 ease-linear duration-150">
              Contact
            </button>
          </motion.section>
          <motion.section
            className="flex-1"
            initial={{ position: "relative", bottom: -300, opacity: 0 }}
            animate={{ opacity: 1, position: "relative", bottom: 0 }}
            transition={{ duration: 1, delay: 0.1 }}
          >
            <Canvas style={{ height: "100vh" }}>
              <Cube />
            </Canvas>
          </motion.section>
        </section>
      </section>
      {/* About */}
      <section className="w-full h-auto min-h-screen bg-neutral-100 py-10 px-40">
        <h1 className="text-center text-2xl font-medium text-yellow-500 mb-1">
          Do You Want To Know
        </h1>
        <h1 className="text-center text-3xl font-black text-neutral-900">
          About Me
        </h1>
        <div className="w-40 h-40 bg-[url('http://surl.li/qvnqx')] bg-contain rounded-full m-auto mt-12 mb-10"></div>
        <p className="font-medium text-center mb-10 text-md">
          Hi! I'm from chennai. I did my UG at "The New College" (Autonomous) in
          royapettah. I completed my SSLC & HSC with 75% above at St.Gabriel's
          Hr Sec School in broadway. I'm a MERN stack developer. I would like to
          share my learning experiences with you. I have been learning web
          development languages for 1 year. I started to learn these stuff's
          during my 2nd year of UG. I did a lot of beginner projects such as Tic
          Tac, Todo app, small frontend websites etc in my learning period. I
          gained good experiences from that. After learnt these basic languages,
          I've started to learn frameworks. I have chosen React framework.
        </p>
        <p className="font-medium text-center text-md">
          Let's talk about my react journey. At the beginning stage, I suffered
          to understand the basics of react. Because no one taught me and i
          don't have good guidance for my career. But i never retracted. Now
          today, I built this portfolio using react js. I learnt mern stack
          languages by myself. So, I'm a self learner and motivated person.
        </p>
      </section>
      {/* Skills */}
      <section className="w-full h-auto bg-white py-10 px-40">
        <h1 className="text-center text-2xl font-medium text-yellow-500 mb-1">
          Why Choose Me
        </h1>
        <h1 className="text-center text-3xl font-black text-neutral-900">
          My Experience Area
        </h1>
        <section className="grid grid-cols-3 mt-12 gap-10">
          <SkillCard2
            url={"http://surl.li/qvqis"}
            name={"Html"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvqrx"}
            name={"Css"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvque"}
            name={"Javascript"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvqvw"}
            name={"React js"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvqyq"}
            name={"Node js"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvqyq"}
            name={"Express js"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvqzm"}
            name={"Tailwind css"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvrat"}
            name={"MongoDb"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvrch"}
            name={"SQL"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvrdo"}
            name={"Webpack 5"}
            rate={"3"}
            percent={70}
          />
          <SkillCard2
            url={"http://surl.li/qvrhr"}
            name={"Python"}
            rate={"4"}
            percent={90}
          />
          <SkillCard2
            url={"http://surl.li/qvrgc"}
            name={"Microservices"}
            rate={"3"}
            percent={70}
          />
          <SkillCard2
            url={"http://surl.li/qvrkq"}
            name={"Aws EC2 & S3"}
            rate={"3"}
            imageWidth="w-29"
            percent={70}
          />
          <SkillCard2
            url={"http://surl.li/qvwlv"}
            name={"Git & Github"}
            rate={"4"}
            imageWidth="w-38"
            percent={90}
          />
        </section>
        {/* <section className="grid grid-cols-5 mt-12 gap-10">
          <SkillCard
            url={"http://surl.li/qvqis"}
            name={"Html"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvqrx"}
            name={"Css"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvque"}
            name={"Javascript"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvqvw"}
            name={"React js"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvqyq"}
            name={"Node js"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvqyq"}
            name={"Express js"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvqzm"}
            name={"Tailwind css"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvrat"}
            name={"MongoDb"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvrch"}
            name={"SQL"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvrdo"}
            name={"Webpack 5"}
            rate={"3"}
            percent={70}
          />
          <SkillCard
            url={"http://surl.li/qvrhr"}
            name={"Python"}
            rate={"4"}
            percent={90}
          />
          <SkillCard
            url={"http://surl.li/qvrgc"}
            name={"Microservices"}
            rate={"3"}
            percent={70}
          />
          <SkillCard
            url={"http://surl.li/qvrkq"}
            name={"Aws EC2 & S3"}
            rate={"3"}
            imageWidth="w-29"
            percent={70}
          />
          <SkillCard
            url={"http://surl.li/qvwlv"}
            name={"Git & Github"}
            rate={"4"}
            imageWidth="w-38"
            percent={90}
          />
        </section> */}
      </section>
      {/* Portfolio */}
      <section className="w-full h-auto  bg-neutral-100 py-10 px-40  bg-neutral-950">
        <h1 className="text-center text-2xl font-medium text-yellow-500 mb-1">
          Portfolio
        </h1>
        <h1 className="text-center text-3xl font-black text-slate-300">
          My Amazing Works
        </h1>
        <section className="grid grid-cols-5 mt-12 gap-6">
          <div>
            <section className="w-full h-80 bg-white rounded-md shadow-md shadow-yellow-400 p-3"></section>
          </div>
          <div>
            <section className="w-full h-80 bg-white rounded-md shadow-md shadow-yellow-400 p-3"></section>
          </div>
          <div>
            <section className="w-full h-80 bg-white rounded-md shadow-md shadow-yellow-400 p-3"></section>
          </div>
          <div>
            <section className="w-full h-80 bg-white rounded-md shadow-md shadow-yellow-400 p-3"></section>
          </div>
          <div>
            <section className="w-full h-80 bg-white rounded-md shadow-md shadow-yellow-400 p-3"></section>
          </div>
        </section>
      </section>
      {/* Blog */}
      <section className="w-full h-auto min-h-screen bg-neutral-100 py-10 px-40">
        <h1 className="text-center text-2xl font-medium text-yellow-500 mb-1">
          Blogs
        </h1>
        <h1 className="text-center text-3xl font-black text-neutral-900">
          My Recent Blogs
        </h1>
        <section className="grid grid-cols-3 mt-12 gap-4">
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
          <div>
            <section className="w-full h-32 bg-white rounded-md shadow-lg p-3"></section>
          </div>
        </section>
      </section>
      {/* Contact */}
      <section className="w-full h-auto bg-neutral-900 py-20 px-20">
        <section className="grid grid-cols-2 text-slate-300">
          <section className="relative">
            <h1 className="text-2xl font-black mb-7">Let's Connect</h1>
            <p>
              If you have any questions or looking developer for your business,
              Contact me without hesitation. I love to help you.
            </p>
            <p className="my-7">
              Please fill out the form on this section to contact with me. Or
              call between 9:00 a.m. and 8:00 p.m. Monday through Friday
            </p>
            <p className="my-3">
              You can also reach me out in social media platforms below.
            </p>
            <p className="mt-10 mb-5 underline underline-offset-8">
              Contact :{" "}
            </p>
            <p className="mb-2">mohammedasiq152@gmail.com</p>
            <p>+91 7397341330</p>
          </section>
          <section className="px-20 ">
            <form>
              <label className="font-medium ">Your Name</label>
              <input className="w-full p-2 my-3 bg-transparent border rounded" />
              <label className="font-medium ">Your Email</label>
              <input className="w-full p-2 my-3 bg-transparent border rounded" />
              <label className="font-medium ">Your Message</label>
              <textarea className="w-full h-32 p-2 my-3 bg-transparent border rounded" />
              <button className="bg-yellow-500 text-neutral-900 font-medium px-4 py-2 mr-5 rounded-md w-40 hover:scale-105 ease-linear duration-150">
                Submit
              </button>
            </form>
          </section>
        </section>
      </section>
      <p className="bg-neutral-950 py-3 px-20 text-slate-300 text-sm">
        Copyright © 2024 Mohammed Asiq. All rights reserved.
      </p>
    </section>
  );
}

export default App;
